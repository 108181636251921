import { createContext, useEffect, useReducer } from 'react';
import PropTypes from 'prop-types';
// utils
import axios from '../utils/axios';
import { isValidToken, setSession } from '../utils/jwt';

// ----------------------------------------------------------------------

const initialState = {
  isAuthenticated: false,
  isInitialized: false,
  user: null
};

const handlers = {
  INITIALIZE: (state, action) => {
    const { isAuthenticated, user } = action.payload;
    return {
      ...state,
      isAuthenticated,
      isInitialized: true,
      user
    };
  },
  LOGIN: (state, action) => {
    const { user } = action.payload;

    return {
      ...state,
      isAuthenticated: true,
      user
    };
  },
  LOGOUT: (state) => ({
    ...state,
    isAuthenticated: false,
    user: null
  }),
  REGISTER: (state, action) => {
    const { user } = action.payload;

    return {
      ...state,
      isAuthenticated: true,
      user
    };
  }
};

const reducer = (state, action) => (handlers[action.type] ? handlers[action.type](state, action) : state);

const AuthContext = createContext({
  ...initialState,
  method: 'jwt',
  login: () => Promise.resolve(),
  logout: () => Promise.resolve(),
  initialize: () => Promise.resolve(),
  register: () => Promise.resolve()
});

AuthProvider.propTypes = {
  children: PropTypes.node
};

function AuthProvider({ children }) {
  const [state, dispatch] = useReducer(reducer, initialState);
  const role = window.localStorage.getItem('role');
  const AccountType = () => {
    let rolRes = 'error';
    if (role === 'supervisor') {
      rolRes = 'supervisors';
    } else if (role === 'company') {
      rolRes = 'companies';
    } else if (role === 'superuser') {
      rolRes = 'generals';
    }
    return rolRes;
  };

  const initialize = async () => {
    try {
      const accessToken = window.localStorage.getItem('accessToken');
      const role = window.localStorage.getItem('role');
      // && isValidToken(accessToken)
      if (accessToken && role) {
        setSession(accessToken, role);

        const response = await axios.get(`/api/${AccountType()}/user`);
        const user = response.data;

        dispatch({
          type: 'INITIALIZE',
          payload: {
            isAuthenticated: true,
            user
          }
        });
      } else {
        delete axios.defaults.headers.common.Authorization;
        dispatch({
          type: 'INITIALIZE',
          payload: {
            isAuthenticated: false,
            user: null
          }
        });
      }
    } catch (err) {
      delete axios.defaults.headers.common.Authorization;
      console.error(err);
      dispatch({
        type: 'INITIALIZE',
        payload: {
          isAuthenticated: false,
          user: null
        }
      });
    }
  };

  useEffect(() => {
    initialize();
  }, []);

  const login = async (username, password, remember) => {
    const response = await axios.post('/api/user/auth/login', {
      username,
      password,
      remember
    });
    const { accessToken, user, role } = response.data;
    setSession(accessToken, role);
    dispatch({
      type: 'LOGIN',
      payload: {
        user
      }
    });
    window.location.reload();
  };

  const logout = async () => {
    try {
      await axios.post('/api/user/auth/logout', {});
      setSession(null);
      dispatch({ type: 'LOGOUT' });
    } catch (e) {
      setSession(null);
      dispatch({ type: 'LOGOUT' });
    }
  };

  const resetPassword = () => null;

  const updateProfile = () => null;

  return (
    <AuthContext.Provider
      value={{
        ...state,
        method: 'jwt',
        login,
        logout,
        initialize,
        resetPassword,
        updateProfile
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

export { AuthContext, AuthProvider };
