import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
import { styled } from '@mui/material/styles';
// @mui
import { Box, Button, Typography, Container } from '@mui/material';
// hooks
import useAuth from '../hooks/useAuth';
// ----------------------------------------------------------------------

RoleBasedGuard.propTypes = {
  permission: PropTypes.string, // Example 'play_multiplayer'
  children: PropTypes.node
};

const RootStyle = styled(Container)(({ theme }) => ({
  display: 'flex',
  height: '100%',
  alignItems: 'center'
}));

// ----------------------------------------------------------------------

export default function RoleBasedGuard({ permission, children }) {
  const { isAuthenticated, user } = useAuth();
  const isPermissionIncluded = user?.perms?.some((permissionToCheck) => permissionToCheck.codename === permission);
  const role = window.localStorage.getItem('role');
  const displayName = role === 'company' ? user?.companyname : `${user?.first_name} ${user?.last_name}`;

  if (!isPermissionIncluded && isAuthenticated) {
    return (
      <RootStyle>
        <Box sx={{ maxWidth: 480, margin: 'auto', textAlign: 'center' }}>
          <Typography variant="h3" paragraph>
            Permission non autorisé
          </Typography>
          <Typography sx={{ color: 'text.secondary', py: 2 }}>
            Désolé {displayName}!, vous n'êtes pas autorisé à accéder à cette section, veuillez réessayer plus tard ou
            contacter le support.
          </Typography>

          <Button to="/" size="large" variant="contained" component={RouterLink}>
            Back to Home
          </Button>
        </Box>
      </RootStyle>
    );
  }
  return <>{children}</>;
}
