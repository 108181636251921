// hooks
import useAuth from '../hooks/useAuth';
//
import { MAvatar } from './@material-extend';
import createAvatar from '../utils/createAvatar';

// ----------------------------------------------------------------------

export default function MyAvatar({ compagnies, ...other }) {
  const { user } = useAuth();
  let displayName = user?.companyname ? user?.companyname : user?.first_name;
  const currentComp = compagnies?.find((e) => e.ref_code === user?.select_company);
  let image = user?.user_photo;
  if (user?.select_company !== 'all' && user?.role === 'superuser') {
    image = currentComp?.user_photo;
    displayName = currentComp?.companyname;
  }

  return (
    <MAvatar src={image} alt={displayName} color={image ? 'default' : createAvatar(displayName).color} {...other}>
      {createAvatar(displayName).name}
    </MAvatar>
  );
}
